.multi-sel > div {
  border: 0;
  background-color: #f5f8fa;
}

.app-footer {margin-top:15px}
// .quote-btn .print-icon:hover, .quote-btn .print-icon:visited, .quote-btn .print-icon:hover i,  .quote-btn .dwld-icon:hover, .quote-btn .dwld-icon:visited, 
// .quote-btn .dwld-icon:hover svg, .quote-btn .email-icon:hover, .quote-btn .email-icon:visited, .quote-btn .email-icon:hover svg  {color:#fff !important;}
.cust-border-bottom {border-bottom: 1px solid #ddd}
.print-icon, .print-icon i {color:#f1416c;}
.dwld-icon, .dwld-icon i {color:#50cd89;}
.wtsp-icon, .wtsp-icon i {color:#ffc700;}
.email-icon, .email-icon i {color:#7239ea;}
.print-icon:hover, .print-icon:hover i, .print-icon:visited i, .print-icon:visited .text-danger,
.dwld-icon:hover, .dwld-icon:hover i, .dwld-icon:visited i, .dwld-icon:visited .text-success,
.wtsp-icon:hover, .wtsp-icon:hover i, .wtsp-icon:visited i, .wtsp-icon:visited .text-warning,
.email-icon:hover, .email-icon:hover i, .email-icon:visited i, .email-icon:visited .text-info
{color:#fff !important}


.multiSelectContainer {
  input {
    margin-top: 6px;
  }
  .searchWrapper {
    min-height: 43px;
    background-color: var(--kt-input-solid-bg);
    border-color: var(--kt-input-solid-bg);
    color: var(--kt-input-solid-color);
    @extend .form-control-solid !optional;
  }
}
.form-check-label {
  color: #3f4254;
}
.form-control,
.form-select,
.multiSelectContainer .searchWrapper {
  .form-control-solid {
    background-color: #f9f9f9;
  }
}
.form-check-input[type=checkbox], .form-check-input[type=checkbox]
// {border-radius:0}
.form-switch.form-check-solid .form-check-input {
  background-color: #f5f5f5;
  // border:0;

  &:checked {
    background-color: var(--kt-form-check-input-checked-bg-color);
  }
}
.multiSelectContainer input {
  background: transparent;
  border: none;
  margin-top: 3px;
  padding-left: 10px;
  padding-top: 3px;
}

.quot-table {
  .logo {
    max-width: 100%;
  }
  thead th,
  tbody td {
    border-color: #e9e9e9;
    border-width: 1px;
    border-style: solid;
    padding: 15px 12px !important;

    &:first-child {
      padding-left: 10px;
    }
  }

  thead th {
    background-color: #f5f5f5;
    font-weight: bold;
    font-size: 16px;
  }
  tbody td {
    font-size: 16px;
    color:#000;
    font-weight: 500;
  }

  .qnty {
    border: 0;
    display: flex;
    justify-content: space-between;
  }
  p {
    margin-bottom: 0;
  }
}
.quot-table > :not(:last-child) > :last-child,
.quot-table tbody tr:last-child td {
  border-bottom: 1px solid #000 !important;

  table {
    width: 70%;
    position: absolute;
    right: 10px;
    border: 0;

    td {
      border: 0 !important;
      padding: 5px !important;
    }
  }
}

.po-tbl tr td {
  border: 0 !important;
}

tr.po-trm-cdtn td {
  border-bottom: 0 !important;
}
.custom_select {
  span + span + div {
    background-color: var(--kt-input-solid-bg);
    border: 1px solid var(--kt-input-border-color);
    border-color: var(--kt-input-solid-bg);
    cursor: pointer;
    color: var(--kt-input-solid-color);
    transition: color 0.2s ease;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.5;
    border-radius: 0.475rem;
    .Select__value-container {
      padding: 0.775rem 1rem;
      .Select__input-container {
        margin: 0;
        padding: 0;
      }
    }
    &:hover {
      border-color: var(--kt-input-solid-bg);
    }
  }
  .Select__menu-list {
    div {
      color: var(--kt-input-solid-color);
      font-size: 1.1rem;
      font-weight: 500;
      cursor: pointer;
      &.Select__option--is-selected {
        background: var(--kt-primary);
        color: var(--kt-primary-inverse);
      }
    }
  }
}

.dist-tbl-overflow {
  // height: 500px;
  overflow-y: auto;
}
.dlt-mdl-icon i {
  font-size: 60px;
}
.status-mian {
  &::after {
    content: '';
    position: absolute;
    left: 34px;
    width: 90%;
    top: 135px;
    bottom: 0;
    background-color: var(--kt-gray-200);
    height: 4px;
  }
  .timeline-badge {
    position: relative;
    z-index: 1;
  }
  .status {
    width: 200px;
    display: inline-block;
  }
}
.statistics-status {
  max-width: 20%;
  width: 100%;
}

@media screen and (max-width: 375px) {
  .statistics-status {
    max-width: 50%;
    &:first-child {
      max-width: 100%;
      margin-top: 30px;
    }
  }
  .inquirys-statues {
    margin-bottom: 18px;
  }
  .users-inquiry {
    margin-bottom: 18px;
  }
  .top-salesman {
    margin-bottom: 18px;
  }
  .interest-grade {
    margin-bottom: 18px;
    margin-top: 18px;
  }
  .offers-grade {
    margin-bottom: 18px;
  }
  .location-grade {
    margin-bottom: 18px;
  }
}

@media screen and (max-width: 425px) {
  .inquirys-statues {
    margin-bottom: 18px;
  }
  .users-inquiry {
    margin-bottom: 18px;
  }
  .top-salesman {
    margin-bottom: 18px;
  }
  .interest-grade {
    margin-bottom: 18px;
    margin-top: 18px;
  }
  .offers-grade {
    margin-bottom: 18px;
  }
  .location-grade {
    margin-bottom: 18px;
  }
}

@media screen and (max-width: 768px) {
  .statistics-status {
    max-width: 50%;
    &:first-child {
      max-width: 100%;
    }
  }
}
.dlt-mdl-icon i {
  font-size: 60px;
}
.css-1okebmr-indicatorSeparator {
  width: 0px !important;
}
.table tr:first-child,
.table th:first-child,
.table td:first-child {
  padding-left: 15px !important;
}
.table tr:last-child,
.table th:last-child,
.table td:last-child {
  padding-right: 15px !important;
}
table.dataTable th {
  white-space: nowrap;
}
.deleteconfirmmessage {
  background-color: #e8fff3;
  font-size: 16px;
  padding: 12px 15px;
}

.grade-report-table tr td,
.grade-report-table tr th {
  border: 1px solid #ddd;
}
.grade-report-table tbody tr:last-child {
  border-bottom: 1px solid #ddd !important;
}
.tp-btn-mn {
  margin-top: -79px !important;
}
.modalheight-scrlbr {
  max-height: 700px;
  overflow-y: auto;
  .modal-content {
    max-height: 100%;
    overflow-y: auto;
  }
}

.status-ribbon {
  position: absolute;
  padding: 8px 5px;
  right: -3px;
  top: 10px;
  border-radius: 0 0.156rem 0.156rem 0;
  box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.5);
  &:after,
  &:before {
    position: absolute;
    content: '';
    display: block;
  }
  &:before {
    width: 0.469em;
    height: 100%;
    padding: 23px 3px;
    top: 0;
    right: -3px;
    background: inherit;
    border-radius: 0 0.313rem 0.313rem 0;
  }
  &:after {
    // width: 0.313em;
    // height: 0.313em;
    background: inherit;
    bottom: -10.313em;
    right: -0.313em;
    border-radius: 0.313em 0 0 0.313em;
    box-shadow: inset -1px 2px 2px rgba(0, 0, 0, 0.3);
  }
}

.materix_table {
  overflow: scroll;
  height: calc(100vh - 210px);
  table {
    thead {
      position: sticky;
      position: sticky;
      position: -webkit-sticky;
      top: 00px;
      align-self: flex-start;
      background: #fff;
      z-index: 9;
         
      &:first-child{
        // background-color: #ddd;
        z-index: 99;
      }
    }
    tbody {
      max-height: calc(100vh - 290px);
      overflow: auto;
    }
    tr{
      td,th{
        &:first-child{
          position: sticky;
          left: 0;
          z-index:9;
        }
      }
    }
    td:first-child{
      background-color: #fff;
      z-index:5;
    }
    th:first-child{
      z-index:99;
      background-color: #f5f8fa;
    }
  }
}

.article-tbl{
th {
  font-weight:600;
  text-align: left;
  padding-top:15px;
  padding-bottom:15px;
  font-size: 14px;
  border-bottom: 1px solid #ddd
}

tbody tr{border-bottom: 1px solid #ddd}
td{
  padding-top:15px;
  padding-bottom:15px;
  font-size: 14px;
}

// tr:last-child td {
//   border-bottom: 1px solid #ddd !important
// }

img {
  max-height: 80px;
}

}

.prdct-dtl-grd div{ flex: 1;}
.prdct-dtl-grd div:last-child{flex: 0;}
.strikethrough {text-decoration: line-through !important;}
.offer-threshold-rate {
  position:absolute;
   right:50px;
    top:35%;

    i{
      color:#f1416c;
    }
}

.rdt input, .rdt input:focus { border:0; background-color: #f5f8fa;}

.table tbody tr.offer-custm-bg:nth-child(2){
background-color: rgba(241, 65, 108, 0.3);
}
.quote-upper-detail .quote-label {width: 180px;
// display: block;
// float: left;
}
.quote-upper-detail .quote-label2 {width: 80px;
  min-width: 80px;
  display: block;
  float: left;}

.date-time {
  background: var(--kt-input-solid-bg);
    border: 0;
    padding: 0.775rem 3rem 0.775rem 1rem;
    width: 100%;
}
input.date-time:focus-visible {
  outline: none;
}

//  css added by dharmendra 
.purchase-text {
  position: relative;
  color: #8b8484;
}

.purchase-text:after {
  content: "";
  position: absolute;
  // top: 2px;
  left: -14px;
  background: #1ba098;
  width: 10px;
  height: 24px;
}

.purchase-view-pdng, .sales-view-pdng{
  padding:80px 30px 80px 60px;
}

.purchase-view-pdng .quot-table  thead th, .sales-view-pdng .quot-table  thead th,
.purchase-view-pdng .quot-table tbody td, .sales-view-pdng .quot-table tbody td {
  border-color: #000 !important;
}
.sales-view-pdng .quot-table { border:0 !important}
.sales-view-pdng .quot-table > :not(:last-child) > :last-child,
 .sales-view-pdng .quot-table tbody tr:last-child td { border:0 !important}

 .sales-view-pdng .table-responsive .quot-table > :not(:last-child) > :last-child,
 .sales-view-pdng .table-responsive .quot-table tbody tr:last-child td { border:1px solid #000 !important}


.po-trm-cdtn p, .quot-tablepo-trm-cdtn p{margin-bottom: 5px !important;}

.dropdown-toggle.remove-toggle-icon::after{ display: none;}

.offer-with-price-table thead th, .offer-with-price-table tbody tr td {
  font-weight: 700 !important;
  font-size: 14px !important;
  color: #000 !important;

}

.prchs-swch-btn .form-switch .form-check-input {
  width: 5.25rem !important;
  height: 35px;
}